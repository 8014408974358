body {
  background: linear-gradient(45deg, #d42222, #22d46c, #2277d2);
  background-size: 600% 600%;
  animation: GradientBackground 10s ease infinite;
  height: 100%;
  margin: 0;
}

p,
h4 {
  font-family: "Montserrat", sans-serif;
}

h4 {
  margin-top: 1em;
  margin-bottom: 1em;
}

@keyframes GradientBackground {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.funcScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  margin-top: 40%;
}

.funcScreen p {
  font-size: x-large;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

@media screen and (min-width: 749px) {
  .funcScreen {
    margin-top: 0;
    height: 100vh;
  }
  .funcScreen p {
    font-size: xxx-large;
  }
}

.funcScreen svg {
  display: block;
}

.card {
  /* Add shadows to create the "card" effect */
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  background-color: ghostwhite;
  height: 10%;
  margin: 2%;
  animation-duration: 1.5s;
  animation-name: slidein;
}

.card #icon {
  width: 10%;
  padding: 16px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card h4 {
  margin-bottom: 4px;
}

.card p {
  margin-top: 4px;
}

/* Add some padding inside the card container */
.container {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 16px;
  padding-left: 8px;
  width: 90%;
}

@keyframes slidein {
  from {
    margin-top: 100%;
  }
}
