body {
  height: 100%;
  background: linear-gradient(45deg, #d42222, #22d46c, #2277d2) 0 0 / 600% 600%;
  margin: 0;
  animation: 10s infinite GradientBackground;
}

p, h4 {
  font-family: Montserrat, sans-serif;
}

h4 {
  margin-top: 1em;
  margin-bottom: 1em;
}

@keyframes GradientBackground {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.funcScreen {
  height: 100%;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
  display: flex;
}

.funcScreen p {
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  font-family: Montserrat, sans-serif;
  font-size: x-large;
}

@media screen and (min-width: 749px) {
  .funcScreen {
    height: 100vh;
    margin-top: 0;
  }

  .funcScreen p {
    font-size: xxx-large;
  }
}

.funcScreen svg {
  display: block;
}

.card {
  height: 10%;
  background-color: #f8f8ff;
  border-radius: 5px;
  flex-direction: row;
  margin: 2%;
  transition: all .3s;
  animation-name: slidein;
  animation-duration: 1.5s;
  display: flex;
  box-shadow: 0 4px 8px #0003;
}

.card #icon {
  width: 10%;
  padding: 16px;
}

.card:hover {
  box-shadow: 0 8px 16px #0003;
}

.card h4 {
  margin-bottom: 4px;
}

.card p {
  margin-top: 4px;
}

.container {
  width: 90%;
  padding: 2px 16px 2px 8px;
}

@keyframes slidein {
  from {
    margin-top: 100%;
  }
}

/*# sourceMappingURL=index.0b2a467f.css.map */
